import { SelectionModel } from '@angular/cdk/collections';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {Component, OnInit, ViewChild, Input} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Workflows } from 'src/app/data/workflows';
import { StorageService } from 'src/app/services/storage.service';
import { FilterTableComponent } from '../filter-table/filter-table.component';
import { WorkflowCreateComponent } from './workflow-create/workflow-create.component';
import { UserWfsService } from '../../services/user-wfs.service';
import { merge, of } from 'rxjs';
import { startWith, switchMap, map, catchError } from 'rxjs/operators';
import { PaginatedAnswer } from 'src/app/data/response';
import { CompanyService } from '../../services/company.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Template } from '../../data/template';
import { WfTemplateService } from 'src/app/services/wf-template.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import {CredentialsService} from "../../services/credentials.service";
import { MatSlideToggle } from '@angular/material/slide-toggle';
import {OkDialogComponent} from "../ok-dialog/ok-dialog.component";

@Component({
  selector: 'app-workflows',
  templateUrl: './workflows.component.html',
  styleUrls: ['./workflows.component.less']
})
export class WorkflowsComponent implements OnInit {

  allColumns = [{value:'company', name: 'Kapcsolódó cég'},  {value:'contract', name: 'Szerződés'},  {value:'businessYearStart', name: 'Üzleti év kezdete'}, {value:'businessYearEnd', name: 'Üzleti év vége'},  {value:'label', name: 'Címke'}, {value:'status', name: 'Státusz'}]

  displayedColumns: string[] = ['select', 'company', 'contract', 'businessYearStart', 'businessYearEnd', 'label', 'status'];

  selection = new SelectionModel<Workflows>(false, []);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  pageSize: number = 10;
  currentPage: number = 1;
  resultsLength = 0;
  isLoadingResults = true;
  gvSchema_id = null;

  companySelectionItems=[]

  statusSelectionItems = [];
  workflowMainGroupSelectionItems = [];
  wfeStatusSelectionItems = [];

  previousDateSelectionItems = [];
  templateSelectionItems = [];
  workflowTemplateSelectionItems = [];

  strictAssignmentControllSelectionItems: any = [{value: true, name: "Yes"}, {value: false, name: "No"}];
  strictClosingControllSelectionItems: any = [{value: true, name: "Yes"}, {value: false, name: "No"}];

  isEdit = false;

  template;
  elements = [];

  //TODO contract_id?, deleted
  _showOnlyActiveWorkflows = true;
  set showOnlyActiveWorkflows(value: boolean) {
    this._showOnlyActiveWorkflows = value;
//    this.loadTableData(this.getFilterObject(this.currentPage,this.pageSize,this.customerSelectionForm.controls.customer.value, this.showOnlyActiveWorkflows));
    //if(this.paginator)this.paginator.pageIndex = 0;
    //this.currentPage = 1;
    //this.getWfs(this.customerSelectionForm.controls.customer.value);
      //this.loadTableData(this.getFilterObject(this.currentPage,this.pageSize,this.customerSelectionForm.controls.customer.value, this.showOnlyActiveWorkflows));
      /*
       "customer_id": this.customerSelectionForm.controls.customer.value,
       "select": {
       "operator": "AND",
       "filters": [
       {
       "column": "customer_id",
       "relation": "=",
       "value": this.customerSelectionForm.controls.customer.value
       }
       ]
       },
       "deleted": !this.showOnlyActiveWorkflows
       */
  }

  get showOnlyActiveWorkflows() {
    return this._showOnlyActiveWorkflows;
  }

  workflows: Workflows[];
  contracts = [];
  companies = [];

  @Input() customerId: string;

  isTemplate: boolean = false;
  setIsTemplate(event){
    this.isEdit = true;
    this.template = null;
    this.isTemplate = event.value;
    setTimeout(()=>{
      this.getWfs(this.customerSelectionForm.controls.customer.value, event.template);
    })
  }

  isDetail: boolean = false;
  setIsDetail(event){
    this.isDetail = event;
  }

  customerSelectionForm: FormGroup = this.formBuilder.group({
    customer: null
  });

  translatableTexts: any[] = [
    {value: "common_message_workflow_save_successful", name: ""},
    {value: "error_message_workflow_save_unsuccessful", name: ""}
  ];

  constructor(private dialogService: MatDialog, private storageService: StorageService, private service: UserWfsService, private companyService: CompanyService, private formBuilder: FormBuilder, private router: Router, public wfTemplateService: WfTemplateService, private snackBar: MatSnackBar, private translateService: TranslateService) {
    if (this.router.getCurrentNavigation().extras?.state?.customer){
      let id = this.router.getCurrentNavigation().extras?.state?.customer;
      this.customerSelectionForm.controls.customer.setValue(id);
    }

   }

  getRole(): 'admin' | 'user' | 'owner' {
    return sessionStorage.getItem('role') as any;
  }

   getFilterObject(page, size, customerId, activeOnly){
     let answer = {
       "page": page,
       "size": size
     }
     let select = {}
     if(customerId || activeOnly){
       select['operator'] = "AND";
       select['filters'] = [];
       if(customerId){
         select['filters'].push({column:"workflow.customer_id", relation:"=", value:customerId})
       }
       if(activeOnly){
         select['filters'].push({column:"workflow_status_sid", relation:"ne", value:"WF_STAT_INACTIVE"})
       }
       answer['select'] = select;
     }
     return answer;
   }

  ngOnInit(): void {
    this.showOnlyActiveWorkflows = false;
    this.storageService.readJsonStorage({"name": ["workflowsTableColumns"]}).subscribe((response) => {
      if(response.workflowsTableColumns){
        this.displayedColumns = JSON.parse(response.workflowsTableColumns).json; 
        // console.log(this.displayedColumns);
      }
    })

    this.service.getWfStatuses().subscribe((response)=>{
      response.payload.forEach((status)=>{
        if (status.is_active){
          this.statusSelectionItems.push({name: status.sid, value:status.sid})
        }
      })
    })

    // this.service.getWfeStatuses().subscribe((response)=>{
    //   response.payload.forEach((status)=>{
    //     if (status.is_active){
    //       this.wfeStatusSelectionItems.push({name: status.sid, value:status.sid})
    //     }
    //   })
    // })


    
    this.companyService.getCompanies().subscribe((response)=>{
      // console.log("Company:");
      response.payload.content.forEach((company)=>{
        // console.log(company);
        if (company.is_active){
          this.companies.push({name: company.short_name, value: company.id, currency_iso_code: company.currency_iso_code, validity: company.customer_status_sid})
        }
      })
      this.companySelectionItems = this.companies.sort((a,b) => {
        if(a.name > b.name)
          return 1;
        
        if(b.name > a.name)
          return -1;

        return 0;
      });
      // console.log(this.companies);

     /* this.companySelectionItems.forEach((company) => {
        this.companyService.getContracts(company.value).subscribe((response)=>{
          response.payload.content.forEach((contract)=>{
            if(contract.is_active){
              this.contracts.push(contract)
            }
          })
        })
      })*/
    })

    
    this.customerSelectionForm.controls.customer.valueChanges.subscribe((value)=>{
      this.getWfs(value);
    })

    this.service.getWfs("workflows").subscribe((response)=>{
      response.payload.content.forEach((wf)=>{
        //if (wf.is_active){
          this.previousDateSelectionItems.push({name: wf.caption, value: wf.id});
          
        //}
      })
    })

    // this.wfTemplateService.getWfElementGroups(CredentialsService.role).then((response)=>{
    //   response.payload.forEach((group)=>{
    //    // if (group.is_active){
    //       this.workflowMainGroupSelectionItems.push({name:group.caption, value:group.id})
    //     //}
    //   })
    // })
/*    this.wfTemplateService.getWfElementGroups(CredentialsService.role).then((response)=>{
      let forTranslate = [];
      response.payload.forEach((group0)=>{
        if(group0.fix_code)forTranslate.push(group0.fix_code);
      });
      let translated = [];
      this.translateService.stream(forTranslate).subscribe((res: string) => {
        forTranslate.forEach((single)=>{
          translated[single] = res[single];
        });
        response.payload.forEach((group)=>{
          if (group.is_active){
//          this.workflowMainGroupSelectionItems.push({name:group.fix_code?(this.translateService.get(group.fix_code).subscribe((res:string)=>{console.log(res);return res})):group.caption, value:group.id})
//            this.workflowMainGroupSelectionItems.push({name:(translated[group.fix_code]?translated[group.fix_code]:group.caption), value:group.id})
          }
        });
      });
    })*/

    this.wfTemplateService.listWfTemplates(CredentialsService.role).subscribe((response) => {
      response.payload.content.forEach((template) => {
        this.templateSelectionItems.push({name:template.caption, value: template.id});
      })
    })

    this.translateService.stream(['common_message_workflow_save_successful', 'error_message_workflow_save_unsuccessful']).subscribe((res: string) => {
      this.translatableTexts = [
        {value: "common_message_workflow_save_successful", name: res["common_message_workflow_save_successful"]},
        {value: "error_message_workflow_save_unsuccessful", name: res["error_message_workflow_save_unsuccessful"]}
      ];
    });

  }

  getWfs(value, template?){
      this.loadTableData(this.getFilterObject(this.currentPage,this.pageSize,value, this.showOnlyActiveWorkflows), template)
      // console.log(this.workflows);
  }

  ngAfterViewInit() {
    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.currentPage = 1;
    });
    if(this.customerSelectionForm.controls.customer.value){
      this.getWfs(this.customerSelectionForm.controls.customer.value);
    }
    this.loadTableData(this.getFilterObject(this.currentPage,this.pageSize,this.customerSelectionForm.controls.customer.value, this.showOnlyActiveWorkflows))

  }

  loadTableData(params, template?){
    merge(this.sort.sortChange, this.paginator.page)
    .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.service.getWfsTable(
            params
          );
        }),
        map((data: PaginatedAnswer<Workflows>) => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.resultsLength = data.payload.total;

          return data.payload.content;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          // Catch if the GitHub API has reached its rate limit. Return empty data.
          return of([]);
        })
    ).subscribe((data) => {
      // console.log(data);
      this.workflows = data;


      if (this.isEdit){
        let index = this.workflows.findIndex(data=>data.id == template.id)
        if (index > -1){
          this.selection.select(this.workflows[index]);
        }
        this.isEdit = false;
      }
      else{
        this.selection.clear();
      }
    });
  }

  handlePage(event){
    this.currentPage = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.selection.clear();
    this.loadTableData(this.getFilterObject(this.currentPage,this.pageSize,this.customerSelectionForm.controls.customer.value, this.showOnlyActiveWorkflows));
  }

  createDateString(date){
    return new Date(date).toLocaleDateString(localStorage.getItem("language").replace("_", "-"))
  }

  findSelectionItem(selection, value){
    return selection.find(item => item.value == value);
  }

  findById(selection, id){
    return selection.find(item => item.id == id)
  }

  getContractCode(wf){
    let contract = wf.contract;
    this.contracts.push(contract);
    return wf.contract.contract_code;
  }

  getCompanyName(wf){
    let company = this.companies.find(company => company.value == wf.customer_id);
    if(company){
      return company.name;
    }
  }

  filterTableColumns(){
    const dialog = this.dialogService.open(FilterTableComponent);
    dialog.componentInstance.tableColumns = this.allColumns;
    dialog.componentInstance.displayedColumns = this.displayedColumns;
    dialog.componentInstance.table = "workflows";
    dialog.afterClosed().subscribe(() => {
        this.storageService.readJsonStorage({"name": ["workflowsTableColumns"]}).subscribe((response) => {
          if (response.workflowsTableColumns){
            this.displayedColumns = JSON.parse(response.workflowsTableColumns).json;      
          }       
      })
    })
  }

  reorderColumns(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.displayedColumns, event.previousIndex, event.currentIndex);
    let body = {
      "name": "workflowsTableColumns",
      "value": {
        "json": this.displayedColumns
      }}
    this.storageService.writeJsonStorage(body).subscribe();
  }

  createWorkflow(){
    // const selectedCompany = this.customerSelectionForm.controls.customer.value;
    // console.log("Temp:");
    // console.log(this.selection.selected[0]);

    // console.log(this.customerSelectionForm.controls.customer);
    // console.log(selectedCompany)
    const dialog = this.dialogService.open(WorkflowCreateComponent);
    dialog.componentInstance.previousDateSelectionItems = this.previousDateSelectionItems;
    dialog.componentInstance.templateSelectionItems = this.templateSelectionItems;
    dialog.componentInstance.workflowCompanySelectionItems = this.companySelectionItems;
    dialog.componentInstance.workflows = this.workflows;
    dialog.componentInstance.selectedCompany = this.customerSelectionForm.controls.customer
    dialog.componentInstance.selectedRow = this.selection.selected[0];
    //dialog.componentInstance.workflowTemplateSelectionItems = this.workflowTemplateSelectionItems;
    dialog.afterClosed().subscribe((response) => {
      if (response){
        if (response.type == "OK"){
          this.snackBar.open(this.translatableTexts.find(element => element.value === "common_message_workflow_save_successful").name, null, {panelClass: 'success-snackbar'});
          this.getWfs(this.customerSelectionForm.controls.customer.value, response.payload);
        }
        else{
          this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_workflow_save_unsuccessful").name, null, {panelClass: 'error-snackbar'});
          let dialog = this.dialogService.open(ErrorDialogComponent);
          dialog.componentInstance.error = response.payload;
        }
      }

    })
  }

  editWorkflow(id){
    this.isTemplate = true;
    this.service.getWf(this.selection.selected[0].id).subscribe((response)=>{
      this.isTemplate = true;
      this.template = response.payload;
      console.log("Contract:"+this.template.contract_id);
    })
    
  }

  deleteWorkflow() {
    const dialog = this.dialogService.open(OkDialogComponent);
    dialog.componentInstance.title = 'Figyelem';
    dialog.componentInstance.description =
        'Biztos benne, hogy törölni szeretné a kiválasztott Workflow-t?';
    dialog.componentInstance.positiveButtonText = "Igen";
    dialog.componentInstance.negativeButtonText = "Nem";
    dialog.afterClosed().subscribe(response => {
      if (response) {
        this.service.deleteWorkflow(this.selection.selected[0].id).subscribe((resp) => {
          if (resp){
            if (resp.type == "OK"){
              this.snackBar.open("A workflow törlése sikeres", null, {panelClass: 'success-snackbar'});
            }
            else{
              this.snackBar.open("A workflow törlése sikertelen", null, {panelClass: 'error-snackbar'});
              let dialog = this.dialogService.open(ErrorDialogComponent);
              dialog.componentInstance.error = resp.payload;
            }
          }

        });
      }
    })
  }

  viewWorkflow(){
    this.elements = [];
    this.service.getWfes(this.selection.selected[0].id).subscribe((response)=>{
      // console.log(response);
      this.gvSchema_id = null;
      if(response && response.payload.content) {
        response.payload.content.forEach((wfe) => {
          if (wfe.is_active) {
            this.elements.push(wfe);
            if (wfe.workflow_element_type_sid == 'WFE_TYPE_GV')this.gvSchema_id = wfe.id;
          }
        })
      }
      this.elements.sort((a, b) => {
        if (a.workflow_element_group.serial_number > b.workflow_element_group.serial_number) {
          return 1
        }
        if (a.workflow_element_group.serial_number < b.workflow_element_group.serial_number) {
          return -1
        }
        return 0;
      });

      this.isDetail = true;
    })
  }

  showOnlyActiveWorkflowsChg(){
    this.getWfs(this.customerSelectionForm.controls.customer.value);
  }
}
